import { Link } from "react-router-dom";

function Tile(props) {
    const { title, image, to } = props;

    return (
        <Link to={`${to}/`}>
            <div className="relative my-4 py-24 rounded-xl border bg-cover bg-center shadow-xl" style={{ backgroundImage: `url(/assets/images/${image})` }} >
                <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 rounded-xl z-10"></div>

                <p className="absolute bottom-0 left-0 w-1/2 z-20 p-3 text-xl text-white uppercase">{title}</p>

                <div className="absolute bottom-0 right-0 m-4 p-2 rounded-full bg-blue-500 z-20" >
                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                </div>
            </div>
        </Link>
    );
}

export default Tile;
