import * as Dialog from "@radix-ui/react-dialog"
import { trackDataV2 } from "../apis/tracking";
import parse from 'html-react-parser';
import Footer from "./Footer";

function PlaceModal(props) {
    const { place } = props;

    const onClickHandler = () => {
        trackDataV2(3, "1e8ve3m3z", "placeview", { name: place.name }, false)
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger className="flex w-full mt-4 mb-8 border rounded-xl shadow-xl" onClick={onClickHandler}>
                <div className="w-48">
                    <img className="w-32 h-32 p-2 object-cover rounded-xl" src={place.image_url || place.cover_image} alt="Immagine rappresentativa del posto" />
                </div>

                <div className="flex flex-col w-full space-y-4 p-2">
                    <p className="font-bold text-left">{place.name}</p>

                    <div className=" flex justify-between items-center">
                        <p className="underline text-blue-500">Leggi di più</p>

                        <p className="p-2 w-8 bg-blue-500 shadow-2xl shadow-black rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                        </p>
                    </div>
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content className="max-w-md fixed w-full top-0 left-0 right-0 bottom-0 m-auto overflow-y-scroll bg-white z-30">
                        <div className="flex justify-center items-center">
                            <Dialog.Trigger className="outline-none absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                            </Dialog.Trigger>
                        </div>

                        <img className="w-full max-h-52 object-cover" alt={place.name} src={place.image_url} />

                        <div className="p-8 py-4 space-y-4">
                            <div className="space-y-6">
                                <p className="pt-4 text-2xl font-bold">{place.name}</p>
                                {place.address && <p>Indirizzo: {place.address}</p>}

                                <div className="space-y-4">
                                    {parse(place.content)}
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root >
    );
}

export default PlaceModal;
