import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import * as Tabs from "@radix-ui/react-tabs";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";
import clsx from "clsx";

function Entertainment() {
    const { t } = useTranslation();

    const [tabName, setTabName] = useState("tab1");
    const [isLoaded, setIsLoaded] = useState(false);

    const TabsRoot = Tabs.Root;
    const TabsList = Tabs.List;
    const TabsTrigger = Tabs.Trigger;
    const TabsContent = Tabs.Content;

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewcinemateatro", {}, false);
    }, []);

    const onClickHandler = (e) => {
        setTabName(e.target.id);
    }

    return (
        <div>
            <TabsRoot defaultValue="tab1" className="">
                <div className="relative">
                    <div className="h-56 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/cinema.png)` }}></div>

                    <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 z-10"></div>

                    <Link to={`/`} className="absolute top-0 m-4 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full z-20">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                    </Link>

                    <p className="absolute bottom-0 p-4 text-2xl text-white z-20 uppercase">{t("home.tiles.cinema")}</p>
                </div>

                <TabsList className="bar overflow-x-scroll whitespace-nowrap flex justify-center p-4 space-x-12">
                    <TabsTrigger className={clsx("uppercase border-b-2", { "border-blue-500": tabName == "tab1", "text-gray-400 border-white": tabName != "tab1" })} id="tab1" value="tab1" onClick={onClickHandler}>Teatro</TabsTrigger>
                    <TabsTrigger className={clsx("uppercase border-b-2", { "border-blue-500": tabName == "tab2", "text-gray-400 border-white": tabName != "tab2" })} id="tab2" value="tab2" onClick={onClickHandler}>Cinema</TabsTrigger>
                </TabsList>

                <TabsContent value="tab1">
                    <div className="px-6 py-4 space-y-4">
                        <p>Il Teatro di Arzignano, è ubicato lungo il centralissimo corso Mazzini di Arzignano, all'interno della storica Villa Mattarello. L'edificio, da poco ristrutturato, ospita quattrocento posti a sedere.
                            Ogni anno, viene organizzata un'importante rassegna teatrale con opere ed attori di prim'ordine sulla scena nazionale.
                            Il Teatro Mattarello è un fiore all’occhiello per la cultura e l’intrattenimento di Arzignano. Il direttore artistico Anderloni ha saputo dargli nuovo lustro e la splendida stagione che ci attende è davvero di alto livello, innovativa e sorprendente per la varietà di tematiche e i grandi nomi portati in scena.</p>

                        <a className="block py-2 underline text-blue-700" href="https://inarzignanonews.it/resource.attachment?id=10868&filename=SCARICA%20IL%20LIBRETTO.pdf&temporary=false" target="_blank" >Scarica la Rassegna Teatrale 2022-2023 con tutti gli spettacoli e le info</a>
                    </div>
                </TabsContent>

                <TabsContent value="tab2">
                    <div className="px-6 py-4 space-y-4">
                        <p>Il Cinema Charlie Chaplin di Arzignano è uno storico cinema ora multisala situato in centro città, <span className="font-bold">Via Campo Marzio, 14.</span> Il cinema dispone di <span className="font-bold">3 sale</span>con tecnologie audio e video all’avanguardia, compresa la riproduzione 3D e un’ottima acustica. Le poltrone sono comode e il personale molto gentile. Il locale è gestito in ambito familiare e risulta molto pulito. Inoltre è disponibile un piccolo bar per popcorn, dolciumi e bibite. I Prezzi sono convenienti con <span className="font-bold">8 euro intero e 6 euro il ridotto. Abbonamento 10 film a 50 euro.</span>Le pellicole proiettate sono sempre aggiornate alle ultime uscite.</p>
                        <p>Gli <span className="font-bold">abbonamenti</span> per assistere a più film sono tornati disponibili al costo di 10 ingressi a 50€. Info e Programmazione: <a className="underline text-blue-700" href="https://charliechaplincinemas.blogspot.com/" target="_blank">https://charliechaplincinemas.blogspot.com/</a></p>
                        <p><span className="font-bold">Telefono</span> è <a className="underline text-blue-700">+39 0444670183</a></p>
                        <div>
                            <p className="font-bold">PRENOTAZIONE</p>
                            <a className="pr-1 underline text-blue-700" href="mailto:cinemar@libero.it ">cinemar@libero.it</a>(invia una mail per prenotare il posto).
                        </div>

                        {!isLoaded && <p className="text-center text-lg font-bold">Caricamento film in programmazione..</p>}
                        <iframe className="w-full h-[40rem]" onLoad={() => setIsLoaded(true)} src="https://docs.google.com/spreadsheets/d/e/2PACX-1vQ0UGe2EmNbmQtzhCesU8FhNkmDUZZar8r19q4vQp9lhMHWfyhjMpMorVImwT5LpKSo91gfRfm1RUkU/pubhtml?gid=1929765413&amp;single=true&amp;widget=true&amp;headers=false"></iframe>
                    </div>
                </TabsContent>
            </TabsRoot>

            <Footer />
        </div>
    );
}

export default Entertainment;
