import * as Dialog from '@radix-ui/react-dialog';
import formats from '../utils/formats';
import { Link } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import positionProvider from '../apis/position';

function InMenuModalWine(props) {
    const { result, userPosition } = props;

    const [distance, setDistance] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [dishesIdx, setDishesIdx] = useState(0);

    const distanceRef = useRef();
    const rootRef = useRef();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        async function getDistance() {
            if (userPosition.lat && userPosition.lng) {
                const res = await positionProvider.getDistanceFromTwoPoints({ lat: userPosition.lat, lng: userPosition.lng }, { lat: result.latitude, lng: result.longitude });
                setDistance(res);
            }
        }

        getDistance();
    }, [userPosition])

    return (
        <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(prev => !prev)}>
            <Dialog.Trigger ref={rootRef} className="relative w-full">
                <div className="cursor-pointer flex mt-4 mb-8 items-center text-left border rounded-xl shadow-xl">
                    <img className="w-32 h-32 p-2 object-cover rounded-xl" src={result.cover_image} alt="Immagine rappresentativa del ristorante" />

                    <div className="flex flex-col w-full space-y-4 p-2">
                        <p className="font-bold">{formats.truncate(result.name, 18)}</p>

                        <p className="text-sm">{formats.truncate(result.address, 28)}</p>

                        {result.in_menu &&
                            <p className="text-sm">{t("businessTile.p")}: {result.in_menu} {result.in_menu_total > 1 && "+ altri piatti"}</p>}

                        <div className="flex justify-between items-center">
                            <Link className="underline text-sm text-blue-500" to={`/arzignano/${result.token}/menu`}>{t("businessTile.a")}</Link>

                            <div className="block px-2 text-sm border rounded-full z-30" ref={distanceRef}>{distance}</div>

                            <div className="w-8 p-2 bg-blue-500 rounded-full shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    {result.wines &&
                        <Dialog.Content className="z-50 max-w-md fixed w-full top-0 left-0 right-0 bottom-0 m-auto overflow-y-scroll bg-white">
                            <div className="flex justify-center items-center">
                                <Dialog.Trigger className="z-50 outline-none absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                                </Dialog.Trigger>

                                <img className="w-full object-cover" alt={result.wines[dishesIdx].name} src={result.wines[dishesIdx].image_url} />
                            </div>

                            <div className="p-8 py-4 space-y-4">
                                <div className="space-y-6">
                                    <p className="pt-14 text-2xl">{result.wines[dishesIdx].nome}</p>
                                    <p className="text-sm">{result.wines[dishesIdx].descrizione}</p>

                                    <Link className="block my-6 underline" to={`/arzignano/${result.token}/menu`}>{result.name}</Link>

                                    <p className="text-gray-600">{result.wines[dishesIdx].price_formatted}</p>

                                    <span className="flex border border-gray-200"></span>
                                </div>

                                <div className="flex justify-center overflow-x-scroll space-x-4">
                                    {Array(result.wines.length).fill(null).map((_, idx) => (
                                        <input className="w-5 h-5" type="radio" id="huey" name="drone" value={idx} defaultChecked={idx == 0 ? true : false} onClick={() => setDishesIdx(idx)} />
                                    ))}
                                </div>
                            </div>
                        </Dialog.Content>}
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default InMenuModalWine;
