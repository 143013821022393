import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";

function Transport() {
    const { t } = useTranslation();

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewtransport", {}, false);
    }, []);

    return (
        <div>
            <div className="relative">
                <div className="h-56 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/transport.png)` }}></div>

                <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 z-10"></div>

                <Link to={`/`} className="absolute top-0 m-4 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full z-20">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <p className="absolute bottom-0 p-4 text-2xl text-white z-20 uppercase">{t("home.tiles.transport")}</p>
            </div>

            <p className="px-6 py-8">Il servizio di trasporto NCC (Noleggio con Conducente) è una soluzione di trasporto privato, che offre ai clienti la possibilità di viaggiare comodamente e in modo sicuro verso la loro destinazione. Con il servizio NCC, i clienti possono prenotare un veicolo con conducente esperto e dedicato, che si occupa di tutti i dettagli del viaggio, dal trasporto alla destinazione fino all’arrivo. Questo servizio è ideale per coloro che cercano un’esperienza di viaggio privata, senza preoccupazioni per la guida o la ricerca di parcheggio.</p>

            <div className="p-6 space-y-6">
                <a className="block" href="tel:3291419111">
                    <p className="font-bold">AC AUTONOLEGGIO DI COCCO ADRIANA</p>
                    <p>3291419111</p>
                    <p>BMW 520 XD 5 (POSTI)</p>
                </a>

                <a className="block" href="tel:3470636205">
                    <p className="font-bold">AUTOSERVIZI DAL LAGO CLAUDIO</p>
                    <p>3470636205</p>
                    <p>MERCEDES BENZ E 220 D (5 POSTI)</p>
                </a>

                <a className="block" href="tel:3468274088">
                    <p className="font-bold">GRIFO SERVIZI DI ZAPPARONI BRUNO</p>
                    <p>3468274088</p>
                    <p>BMERCEDES BENZ E 220 D (5 POSTI)</p>
                </a>

                <a className="block" href="tel:3356252623">
                    <p className="font-bold">MONICA TOURS DI ALBIERO MONICA</p>
                    <p>3356252623</p>
                    <p>CITROEN C5 5 (POSTI)</p>
                </a>

                <a className="block" href="tel:3356628073">
                    <p className="font-bold">RANCAN PAOLO</p>
                    <p>3356628073</p>
                    <p>WOLKSWAGEN TOURAN (7 POSTI)</p>
                </a>

                <a className="block" href="tel:3485822004">
                    <p className="font-bold">SPEED CAR DI MAELLO FABIO</p>
                    <p>3485822004</p>
                    <p>OPEL VIVARO (9 POSTI)</p>
                </a>

                <a className="block" href="tel:3381552466">
                    <p className="font-bold">TAGLIENTE CIRO</p>
                    <p>3381552466</p>
                    <p>MERCEDES BENZ VITO TOURER (9 POSTI)</p>
                    <p>e MERCEDES BENZ V-KLASSE (8 POSTI)</p>
                </a>
            </div>

            <div className="p-6 space-y-6">
                <p className="font-bold">ARTURO IL TAXI SICURO (e GRATUITO): Ogni venerdì e sabato notte</p>
                <p>Arturo è un servizio di trasporto notturno (a 9 posti) organizzato dal Comune di Arzignano. Operativo ogni venerdì e sabato <span className="font-bold">dalle 23.30 alle 02.30</span>, il servizio gratuito è dedicato ai residenti di Arzignano. Le partenze sono previste dalla stazione centrale della piazza con destinazione specifica davanti alla residenza di ciascun passeggero. Il servizio mira a garantire uno spostamento sicuro in particolare dei <span className="font-bold">giovani</span> nelle ore serali e notturne. Dal 2011 al 2022, il servizio è stato utilizzato da <span className="font-bold">oltre 25 mila passeggeri.</span></p>
                <img src="/assets/images/arturo.png" />
            </div>

            <Footer />
        </div>
    );
}

export default Transport;
