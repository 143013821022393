import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { trackDataV2 } from "../apis/tracking.js";
import Footer from "../components/Footer.jsx";
import LanguageSelector from "../components/LanguageSelector.jsx";
import Tile from "../components/Tile.jsx";

function Home() {
    const { i18n, t } = useTranslation();

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewhome", {}, false);
    }, [])

    return (
        <div className="relative">
            <img className="px-16" src="/assets/images/logo.png" />

            <div className="pt-4">
                {/* <LanguageSelector i18n={i18n} /> */}
            </div>

            <div className="py-10 border-t shadow-inner" style={{ boxShadow: "#dadada 0px -4px 8px 0px" }}>
                <div className="px-8 space-y-10">
                    <Tile className="uppercase" title={t("home.tiles.restaurants")} image="restaurant.png" to="/arzignano" />
                    <Tile className="uppercase" title={t("home.tiles.places")} image="castle.png" to="cosa-visitare" />
                    <Tile className="uppercase" title={t("home.tiles.events")} image="events.png" to="eventi" />
                    <Tile className="uppercase" title={t("home.tiles.cinema")} image="cinema.png" to="cinema-e-teatro" />
                    <Tile className="uppercase" title={t("home.tiles.transport")} image="transport.png" to="trasporti-taxi" />
                    <Tile className="uppercase" title={t("home.tiles.sos")} image="sos.png" to="sos-emergenze" />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Home;
