function Footer() {
    return (
        <div>
            <p className="my-4 text-lg font-bold text-center">Powered by <a className="underline" href="https://mycia.it" target="_blank" rel="noopener">MyCIA</a></p>
            <img className="px-28" src="/assets/images/footer_logo.png" />
        </div>
    );
}

export default Footer;
