import client from "./api-client";
import formats from "../utils/formats";
import { ROOTENDPOINT } from "./constants";

async function getEvents() {
    let response = await client.doGet(formats.formatUrl(ROOTENDPOINT + "events"));

    return response;
}

async function getEvent(id) {
    let response = await client.doGet(formats.formatUrl(ROOTENDPOINT + "events/:id", { id }));

    return response && response[0];
}

export default {
    getEvents,
    getEvent,
}
