import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import clsx from "clsx";
import BusinessTile from "../components/BusinessTile";
import { Fragment, useEffect, useState } from "react";
import { trackDataV2 } from "../apis/tracking";
import TabsSearchBar from "./TabsSearchBar";
import useGeoPosition from "../logic/hooks/useGeoPosition";
import { useTranslation } from "react-i18next";
import InMenuModal from "./InMenuModal";
import InMenuModalWine from "./InMenuModalWine";

function BusinessesWrapperTabs(props) {
    const { restaurants, shops } = props;

    const [activeTab, setIsActiveTab] = useState(0);
    const [searchType, setSearchType] = useState("tab1");
    const [searchText, setSearchText] = useState("");
    const [searchResults, setSearchResults] = useState([]);

    const userPosition = useGeoPosition();
    const { t } = useTranslation();

    const onSelectHandler = (idx) => {
        setIsActiveTab(idx);
        idx == 0 ?
            trackDataV2(3, "1e8ve3m3z", "showrestaurants", {}, false) :
            trackDataV2(3, "1e8ve3m3z", "showshops", {}, false);
    };

    useEffect(() => {
        setSearchResults([]);
        setSearchText("");
    }, [searchType]);

    return (
        <Tabs defaultIndex={0} onSelect={onSelectHandler}>
            <div className="tabs relative">
                {/* <TabList className="bar overflow-x-scroll whitespace-nowrap flex items-center py-6 px-6 text-sm">
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "flex", "items-center", "p-3", "px-6",
                            "rounded-full", "border", "border-solid",
                            "whitespace-no-wrap", "uppercase", { "active bg-blue-500 tab-active text-white": activeTab == 0 }, { "shadow-lg bg-white": activeTab != 0 })}>
                            <svg id="Raggruppa_14" data-name="Raggruppa 14" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 25.98 21.948" className={clsx("w-6", { "fill-white": activeTab == 0 })}><g id="Raggruppa_12" data-name="Raggruppa 12" transform="translate(0)"><path id="Tracciato_4" data-name="Tracciato 4" d="M429.165,277.652l-1.594,1.288a.98.98,0,0,1-.647.231.8.8,0,0,1-.863-.8.891.891,0,0,1,.4-.732l11.238-9.039a2.081,2.081,0,0,1,2.722,0l5.52,4.444v-2.139a.694.694,0,0,1,.755-.715h1.511a.687.687,0,0,1,.747.715v4.563l2.7,2.17a.9.9,0,0,1,.39.732.8.8,0,0,1-.863.8.943.943,0,0,1-.639-.231l-1.585-1.272v9.977a2.278,2.278,0,0,1-2.573,2.4H431.738a2.275,2.275,0,0,1-2.573-2.4Zm16.841,10.717c.8,0,1.187-.374,1.187-1.137V276.253l-7.728-6.209a.594.594,0,0,0-.813,0l-7.728,6.2v10.987c0,.763.39,1.137,1.187,1.137Z" transform="translate(-426.061 -268.098)"></path></g><g id="Raggruppa_13" data-name="Raggruppa 13" transform="translate(5.933 9.622)"><path id="Tracciato_5" data-name="Tracciato 5" d="M470.4,318.531a.989.989,0,1,0,0-1.977h-6.289a.989.989,0,1,0,0,1.977h2.112v4.558a1.033,1.033,0,0,0,2.064,0v-4.558Z" transform="translate(-460.027 -314.905)"></path><path id="Tracciato_6" data-name="Tracciato 6" d="M492.549,309.465a1.011,1.011,0,0,0-1.032.989v3.435h-1.929a1.161,1.161,0,0,0-1.183,1.133v2.627a1.033,1.033,0,0,0,2.064,0v-1.783h1.049v1.783a1.033,1.033,0,0,0,2.064,0v-7.2A1.011,1.011,0,0,0,492.549,309.465Z" transform="translate(-479.198 -309.465)"></path><path id="Tracciato_7" data-name="Tracciato 7" d="M454.487,313.889h-1.929v-3.435a1.033,1.033,0,0,0-2.064,0v7.2a1.033,1.033,0,0,0,2.064,0v-1.783h1.049v1.783a1.033,1.033,0,0,0,2.064,0v-2.627A1.16,1.16,0,0,0,454.487,313.889Z" transform="translate(-450.494 -309.465)"></path></g></svg>
                            <p className="pl-3">{t("businessesWrapperTabs.p_1")}</p>
                        </div>
                    </Tab>
                    <Tab>
                        <div className={clsx("tab", "cursor-pointer", "inline-block", "flex", "items-center", "ml-4", "p-3", "px-6",
                            "rounded-full", "border", "border-solid",
                            "whitespace-no-wrap", "uppercase", { "active bg-blue-500 tab-active text-white": activeTab == 1 }, { "shadow-lg bg-white": activeTab != 1 })}>
                            <svg xmlns="http://www.w3.org/2000/svg" className={clsx("w-6", { "fill-white": activeTab == 1 })} id="Layer_1" x="0px" y="0px" viewBox="0 0 512 512" space="preserve"><g><path d="M487.084,94.833L448,36.207V21.333C448,9.551,438.449,0,426.667,0H85.333C73.551,0,64,9.551,64,21.333v14.874     L24.916,94.833c-2.336,3.504-3.583,7.622-3.583,11.834v42.667c0,18.881,8.255,35.891,21.333,47.623v293.71     C42.667,502.449,52.218,512,64,512h192h106.667H448c11.782,0,21.333-9.551,21.333-21.333v-293.71     c13.078-11.732,21.333-28.742,21.333-47.623v-42.667C490.667,102.455,489.42,98.337,487.084,94.833z M64,113.126L103.084,54.5     c2.336-3.504,3.583-7.622,3.583-11.834h298.667c0,4.212,1.247,8.329,3.583,11.834L448,113.126v36.207     c0,7.182-3.653,13.584-9.176,17.459c-0.534,0.257-1.064,0.53-1.589,0.837c-2.897,1.693-5.869,2.675-8.861,2.949     c-0.565,0.046-1.132,0.088-1.708,0.088c-11.685,0-21.333-9.649-21.333-21.333v-42.667h-42.667v42.667     c0,11.685-9.649,21.333-21.333,21.333c-11.685,0-21.333-9.649-21.333-21.333v-42.667h-42.667v42.667     c0,11.685-9.649,21.333-21.333,21.333c-11.685,0-21.333-9.649-21.333-21.333v-42.667H192v42.667     c0,11.685-9.649,21.333-21.333,21.333c-11.685,0-21.333-9.649-21.333-21.333v-42.667h-42.667v42.667     c0,11.685-9.649,21.333-21.333,21.333c-0.576,0-1.143-0.042-1.708-0.088c-2.991-0.274-5.964-1.256-8.861-2.949     c-0.525-0.307-1.056-0.58-1.589-0.837C67.653,162.917,64,156.515,64,149.333V113.126z M277.333,469.333V320h64v149.333H277.333z      M426.667,469.333H384V298.667c0-11.782-9.551-21.333-21.333-21.333H256c-11.782,0-21.333,9.551-21.333,21.333v170.667H85.333     v-256c16.365,0,31.33-6.197,42.667-16.365c11.337,10.168,26.302,16.365,42.667,16.365s31.33-6.197,42.667-16.365     c11.337,10.168,26.302,16.365,42.667,16.365s31.33-6.197,42.667-16.365c11.337,10.168,26.302,16.365,42.667,16.365s31.33-6.197,42.667-16.365c11.337,10.168,26.302,16.365,42.667,16.365V469.333z" /><rect x="128" y="298.667" width="64" height="85.333" /></g></svg>
                            <p className="pl-3">{t("businessesWrapperTabs.p_2")}</p>
                        </div>
                    </Tab>
                </TabList> */}

                <div className="py-4 px-6">
                    <TabsSearchBar searchType={searchType} setSearchType={setSearchType} searchText={searchText} setSearchText={setSearchText} setSearchResults={setSearchResults} />
                </div>
            </div>

            <div className="px-6">
                <p className="text-sm text-red-400">{userPosition.error}</p>

                <TabPanel>
                    {searchText.length == 0 ?
                        <Fragment>
                            {restaurants.map((business, i) => (
                                <BusinessTile business={business} userPosition={userPosition} key={i} />
                            ))}
                            {/* richiesta di mettere gli shop insieme ai ristoranti */}
                            {shops.map((business, i) => (
                                <BusinessTile business={business} userPosition={userPosition} index={i} key={i} />
                            ))}
                        </Fragment> :
                        searchResults.length > 0 ?
                            searchType == "tab1" ?
                                searchResults.map((result, i) => (
                                    <InMenuModal result={result} userPosition={userPosition} key={i} />
                                )) :
                                searchResults.map((result, i) => (
                                    <InMenuModalWine result={result} userPosition={userPosition} key={i} />)) :
                            <p className="">{t("businessesWrapperTabs.noResults")}</p>}
                </TabPanel>

                <TabPanel>
                    {searchText.length == 0 ? shops.map((business, i) => (
                        <BusinessTile business={business} userPosition={userPosition} index={i} key={i} />
                    )) :
                        searchResults.length > 0 ?
                            searchResults.map((business, i) => (
                                <BusinessTile business={business} userPosition={userPosition} index={i} key={i} />
                            )) :
                            <p className="">{t("businessesWrapperTabs.noResults")}</p>}
                </TabPanel>
            </div>
        </Tabs>
    );
}

export default BusinessesWrapperTabs;
