import React, { createContext, useContext, useEffect, useState } from 'react';
import businessClient from "../../apis/business";
import { useTranslation } from 'react-i18next';

export const BusinessContext = createContext({});

export const BusinessProvider = ({ children }) => {
    const [business, setBusiness] = useState({});
    const [token, setToken] = useState(null);

    const { i18n } = useTranslation();

    useEffect(() => {
        async function handleBusinessLoad() {
            const res = await businessClient.getBusiness(token, i18n.language.substring(0, 2));
            setBusiness(res)
        }

        token && handleBusinessLoad();
    }, [token, i18n.language])

    return (
        <BusinessContext.Provider value={{ business, setToken }}>
            {children}
        </BusinessContext.Provider>
    );
};

export const useGetBusiness = () => useContext(BusinessContext);
