import React, { useRef } from 'react';
import formats from '../utils/formats';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import positionProvider from "../apis/position";
import { useLayoutEffect } from 'react';
import { useTranslation } from 'react-i18next';

function BusinessTile(props) {
    const { business: restaurant, userPosition } = props;

    const [distance, setDistance] = useState();

    const navigate = useNavigate();
    const distanceRef = useRef();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        async function getDistance() {
            if (userPosition.lat && userPosition.lng) {
                const res = await positionProvider.getDistanceFromTwoPoints({ lat: userPosition.lat, lng: userPosition.lng }, { lat: restaurant.latitude, lng: restaurant.longitude });
                setDistance(res);
            }
        }

        getDistance();
    }, [userPosition])

    const onClickHandler = (evt) => {
        if (evt.target == distanceRef.current) {
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${restaurant.address}`);
        } else {
            navigate(`/arzignano/${restaurant.token}/menu`);
        }
    }

    return (
        <div className="cursor-pointer flex mt-4 mb-8 items-center border rounded-xl shadow-xl" onClick={onClickHandler}>
            <img className="w-32 h-32 p-2 object-cover rounded-xl" src={restaurant.image_url} alt="Immagine rappresentativa del ristorante" />

            <div className="flex flex-col w-full space-y-4 p-2">
                <p className="font-bold">{restaurant.name}</p>

                <p className="text-sm">{formats.truncate(restaurant.address, 28)}</p>

                {restaurant.in_menu &&
                    <p className="text-sm">{t("businessTile.p")}: {restaurant.in_menu}</p>}

                <div className="flex justify-between items-center">
                    <Link className="underline text-sm text-blue-500" to={`/arzignano/${restaurant.token}/menu`}>{t("businessTile.a")}</Link>

                    <div className="block px-2 text-sm border rounded-full z-40" ref={distanceRef}>{distance}</div>

                    <Link to={`/arzignano/${restaurant.token}/menu`} className="w-8 p-2 bg-blue-500 rounded-full shadow-2xl shadow-black">
                        <svg xmlns="http://www.w3.org/2000/svg" className="text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                    </Link>
                </div>
            </div>
        </div>
    );
}

export default BusinessTile;
