import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { trackDataV2 } from "../apis/tracking";
import EventModal from "../components/EventModal";
import Footer from "../components/Footer";
import { useState } from "react";
import eventsApi from "../apis/events";

function Events() {
    const { t } = useTranslation();

    const [events, setEvents] = useState([]);

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewevents", {}, false);

        async function loadEvents() {
            const res = await eventsApi.getEvents();
            setEvents(res);
        }

        loadEvents();
    }, []);

    return (
        <div>
            <div className="relative">
                <div className="h-56 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/events.png)` }}></div>

                <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 rounded-xl z-10"></div>

                <Link to={`/`} className="absolute top-0 m-4 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full z-20">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <p className="absolute bottom-0 p-4 text-2xl text-white z-20 uppercase">{t("home.tiles.events")}</p>
            </div>

            {events.length > 0 ?
                events.map((event, i) =>
                    <div className="px-6" key={i}>
                        <EventModal event={event} />
                    </div>) :
                <p className="py-16 text-center font-bold">Nessun evento disponibile</p>}

            <p className="p-6">Ogni anno, il Comune di Arzignano organizza numerose e imperdibili rassegne e manifestazioni culturali. Tra queste, spiccano in particolare "Natale In Arzignano", un evento magico e coinvolgente che celebra la festa più attesa dell'anno, e "Estate In Arzignano", un'esperienza unica che vi porterà alla scoperta di spettacoli, concerti ed eventi all'aperto per tutta la famiglia. Non perdete l'opportunità di partecipare a queste indimenticabili occasioni di intrattenimento e divertimento!
                Scarica i depliant delle rassegne per scoprire il calendario degli eventi in programma.</p>
            <Footer />
        </div>
    );
}

export default Events;
