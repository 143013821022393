import AppRoutes from './AppRoutes';
import './App.css';

function App() {
  return (
    <div className="container mx-auto max-w-md">
      <AppRoutes />
    </div>
  )
}

export default App;
