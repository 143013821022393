import React from 'react';
import searchClient from '../apis/search';
import AsyncSelect from "react-select/async";
import { t } from 'i18next';

function SearchBar(props) {
    const { className, setSearchText, setSearchResults, type } = props;

    const promiseOptions = input => {
        return new Promise(resolve => {
            searchClient.searchFoodTypeOnGroup(type, "1e8ve3m3z", input).then(rex => {
                rex.response.data.forEach((resultRow) => {
                    resultRow.token = resultRow.slug.slice(-9);
                })

                let aux = [];
                rex.response.data.map(result =>
                    type == "plate" ?
                        result.piatti.map(dish => aux.push({ data: result, value: dish.id, label: dish.name })) :
                        result.wines.map(wine => aux.push({ data: result, value: wine.id, label: wine.nome }))
                )
                resolve(aux);
            });
        });
    }

    const handle = (data) => {
        data && setSearchResults([data.data]);
        data ? setSearchText(data.label) : setSearchText("");
    }

    return (
        <AsyncSelect
            className="z-50"
            cacheOptions
            loadOptions={promiseOptions}
            onChange={(data) => handle(data)}
            noOptionsMessage={() => "cerca"}
            isClearable={true}
            placeholder={type == "plate" ? t("businessesWrapperTabs.searchBar.input_1") : t("businessesWrapperTabs.searchBar.input_2")}
        />
    );
}

export default SearchBar;
