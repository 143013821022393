import formats from "../utils/formats";
import apiClient from "./api-client";
import { RESTAURANTSENDPOINT, RESTAURANTSENDPOINTSTAGING } from "./constants";

async function doGetGroupRestaurants(network, city = "", pacchetti = "") {
    var response = await apiClient.doGet(formats.formatUrl(RESTAURANTSENDPOINT, { network, city, pacchetti }));

    return response;
}

export default { doGetGroupRestaurants }
