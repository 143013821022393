import * as Dialog from "@radix-ui/react-dialog"
import { trackDataV2 } from "../apis/tracking";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

function EventModal(props) {
    const { event } = props;

    const onClickHandler = async () => {
        trackDataV2(3, "1e8ve3m3z", "eventview", { name: event.name }, false);
    }

    return (
        <Dialog.Root>
            <Dialog.Trigger className="flex w-full mt-4 mb-8 border rounded-xl shadow-xl" onClick={onClickHandler}>
                <img className="w-28 p-2 object-cover rounded-xl" src={event.image_url} alt="Immagine rappresentativa del posto" />

                <div className="flex flex-col w-full space-y-4 p-2">
                    <div>
                        <p className="font-bold text-left">{event.title}</p>
                        <p className="pt-2 text-sm text-left">{new Date(event.start_date).toLocaleDateString()}</p>
                    </div>

                    <div className="flex justify-between items-center">
                        <p className="underline text-blue-500">Guarda locandina</p>

                        <p className="p-2 w-8 bg-blue-500 shadow-2xl shadow-black rounded-full">
                            <svg xmlns="http://www.w3.org/2000/svg" className="text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                        </p>
                    </div>
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    <Dialog.Content className="max-w-md fixed w-full top-0 left-0 right-0 bottom-0 m-auto overflow-y-scroll bg-white z-30">
                        <Dialog.Trigger className="outline-none mx-8 my-4 rounded-full p-2 bg-white shadow-2xl shadow-black">
                            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                        </Dialog.Trigger>

                        <TransformWrapper>
                            <TransformComponent>
                                <img className="w-screen" src={event.image_url} alt={event.name} />
                            </TransformComponent>
                        </TransformWrapper>
                    </Dialog.Content>
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default EventModal;
