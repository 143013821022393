import * as Tabs from "@radix-ui/react-tabs";
import clsx from "clsx";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import SearchBar from "./SearchBar";

function TabsSearchBar(props) {
    const { searchType, setSearchType, setSearchText, setSearchResults } = props;

    const { t } = useTranslation();

    const TabsRoot = Tabs.Root;
    const TabsList = Tabs.List;
    const TabsTrigger = Tabs.Trigger;
    const TabsContent = Tabs.Content;

    const onclickHandler = (evt) => {
        setSearchType(evt.target.id);
    }

    return (
        <TabsRoot defaultValue="tab1" className="searchbar">
            <TabsList className="bar overflow-x-scroll whitespace-nowrap flex justify-center pb-4 space-x-12">
                <TabsTrigger className={clsx("uppercase border-b-2", { "border-blue-500": searchType == "tab1", "text-gray-400 border-white": searchType != "tab1" })} id="tab1" value="tab1" onClick={onclickHandler}>{t("businessesWrapperTabs.searchBar.input_1")}</TabsTrigger>
                <TabsTrigger className={clsx("uppercase border-b-2", { "border-blue-500": searchType == "tab2", "text-gray-400 border-white": searchType != "tab2" })} id="tab2" value="tab2" onClick={onclickHandler}>{t("businessesWrapperTabs.searchBar.input_2")}</TabsTrigger>
            </TabsList>

            <TabsContent value="tab1">
                <SearchBar className="w-full p-3 border border-gray-400 rounded-lg" type="plate" setSearchText={setSearchText} setSearchResults={setSearchResults} />
            </TabsContent>

            <TabsContent value="tab2">
                <SearchBar className="w-full p-3 border border-gray-400 rounded-lg" type="wine" setSearchText={setSearchText} setSearchResults={setSearchResults} />
            </TabsContent>
        </TabsRoot>
    );
}

export default TabsSearchBar;
