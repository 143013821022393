import apiClient from "./api-client";
import formats from "../utils/formats";
import { COURSESENDPOINT, DISHESENDPOINT } from "./constants";

async function doGet(id, lang) {
    var response = await apiClient.doGet(formats.formatUrl(COURSESENDPOINT, { "id": id, "lang": lang }));

    return response;
}

async function doGetDishInfo(id, lang) {
    var response = await apiClient.doGet(formats.formatUrl(DISHESENDPOINT, { "id": id, "lang": lang }));

    return response;
}

async function doGetIngredientsToDish(dishes, lang) {
    dishes.forEach(dish => {
        doGetDishInfo(dish.id, lang).then(dishInfo => {
            dish.ingredients = [...dishInfo.dish.ingredients];
        })
    });

    return dishes;
}

export default {
    doGet,
    doGetDishInfo,
    doGetIngredientsToDish,
}
