import * as TabsCore from '@radix-ui/react-tabs';
import DishCourses from './DishCourses';
import DrinkCourses from './DrinkCourses';
import { useEffect, useState } from 'react';
import { trackDataV2 } from '../apis/tracking';
import { useTranslation } from 'react-i18next';
import clsx from 'clsx';

function BusinessTabs(props) {
    const { business } = props;

    const [selectedTab, setSelectedTab] = useState(0);
    const [loading, setLoading] = useState(true);
    const [defaultValue, setDefaultValue] = useState();

    const { t } = useTranslation();

    useEffect(() => {
        if (business && business.name) {
            if (business.courses && business.courses.length == 0) {
                setSelectedTab(1);
                setDefaultValue("tab2");
            } else {
                setSelectedTab(0);
                setDefaultValue("tab1");
            }
            setTimeout(() => {
                setLoading(false);
            }, 50);
        }
    }, [business])

    const onValueChangeHandler = (value) => {
        value == "tab1" ?
            trackDataV2(business.id, business.token, "viewrestaurantdishes", {}, false) :
            trackDataV2(business.id, business.token, "viewrestaurantdrinks", {}, false);
    }

    return (
        (loading ?
            <svg xmlns="http://www.w3.org/2000/svg" xlink="http://www.w3.org/1999/xlink" style={{ margin: "auto", backgroundColor: "rgb(255, 255, 255)", display: "block" }} width="200px" height="200px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <path d="M10 50A40 40 0 0 0 90 50A40 42 0 0 1 10 50" fill="#3482F6" stroke="none">
                    <animateTransform attributeName="transform" type="rotate" dur="1s" repeatCount="indefinite" keyTimes="0;1" values="0 50 51;360 50 51"></animateTransform>
                </path>
            </svg>
            : ((business.courses || business.beverages)) ?
                <TabsCore.Root onValueChange={onValueChangeHandler} defaultValue={defaultValue} className="relative">
                    <TabsCore.List aria-label="TabsCore" className="flex px-4 py-4 space-x-4">
                        {business.courses && business.courses.length > 0 &&
                            <TabsCore.Trigger value="tab1" className={clsx("w-1/2 px-4 py-1 whitespace-nowrap font-bold border rounded-full border-blue-500 uppercase", { "text-white bg-blue-500": selectedTab == 0 })} onClick={() => setSelectedTab(0)}>{t("businessTabs.button_1")}</TabsCore.Trigger>}

                        {business.beverages && business.beverages.length > 0 &&
                            <TabsCore.Trigger value="tab2" className={clsx("w-1/2 px-4 py-1 whitespace-nowrap font-bold border rounded-full border-blue-500 uppercase", { "text-white bg-blue-500": selectedTab == 1 })} onClick={() => setSelectedTab(1)}>{t("businessTabs.button_2")}</TabsCore.Trigger>}
                    </TabsCore.List>

                    {business.courses && business.courses.length > 0 &&
                        <TabsCore.Content value="tab1" className="outline-none">
                            <DishCourses business={business} />
                        </TabsCore.Content>}

                    {business.beverages && business.beverages.length > 0 &&
                        <TabsCore.Content value="tab2" className="outline-none">
                            <DrinkCourses business={business} />
                        </TabsCore.Content>}
                </TabsCore.Root>
                : <p className="relative px-3 py-6 text-center font-bold">Il ristorante non ha ancora caricato il suo menu</p>
        )
    );
}

export default BusinessTabs;
