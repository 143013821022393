import formats from "../utils/formats";
import apiClient from "./api-client";
import { SEARCHENDPOINT, SEARCHFOODENDPOINT, SEARCHWINEENDPOINT } from "./constants";

async function searchOnGroup(input, group) {
    var response = apiClient.doGet(formats.formatUrl(SEARCHENDPOINT, { "network": group, "name": input }));

    return response;
}

async function searchFoodTypeOnGroup(type, network, search) {
    var response;
    if (type == "plate") {
        response = await apiClient.doGet(formats.formatUrl(SEARCHFOODENDPOINT, { network, food_type: search }));
    } else {
        response = await apiClient.doGet(formats.formatUrl(SEARCHWINEENDPOINT, { network, wine: search }));
    }

    return response;
}

export default { searchOnGroup, searchFoodTypeOnGroup };
