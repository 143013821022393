import React, { useEffect } from "react";
import BookingHandler from "../components/BookingHandler";
import BusinessTabs from "../components/BusinessTabs";
import formats from "../utils/formats";
import Gallery from "../components/Gallery";
import { trackDataV2 } from "../apis/tracking";
import { useGetBusiness } from "../logic/contexts/BusinessContext";
import { useParams } from "react-router";
import { Link } from "react-router-dom";
import Footer from "../components/Footer";
import { useTranslation } from "react-i18next";
import { useState } from "react";
import TimeTables from "../components/TimeTables";

function Business() {
    const { business, setToken } = useGetBusiness();
    const { token } = useParams();
    const { t } = useTranslation();

    const [showAllDescription, setShowAllDescription] = useState(false);

    useEffect(() => {
        setToken(token);
    }, []);

    useEffect(() => {
        trackDataV2(business.id, business.token, "openbusiness", {}, false);
    }, [business]);

    const shareHandler = () => {
        const data = {
            url: `https://arzignano.mycontactlessmenu.mycia.it/arzignano/${business.token}/menu`,
            text: business.title,
            title: "MyCIA x Arzignano",
        }
        navigator.share(data);
    }

    const onShowAllDescriptionHandler = () => {
        setShowAllDescription(prev => !prev);
    }

    return (
        <div>
            <div className="relative">
                <div className="relative pt-2 pb-16 px-6 space-y-6 bg-blue-500">
                    <Link to="/arzignano" className="absolute block top-0 right-0 m-6 p-2 w-10 h-10 items-center rounded-full bg-white shadow-2xl shadow-black">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" d="M368 368L144 144M368 144L144 368"></path></svg>
                    </Link>

                    <p className="text-white text-4xl pr-10">{business.name}</p>

                    <div>
                        <p className="text-white inline">{showAllDescription ?
                            <div>
                                {business.description}

                                <TimeTables week={business.timetables_formatted} />
                            </div>
                            : formats.truncate(business.description, 150)}</p>
                        <button className="text-white underline" onClick={onShowAllDescriptionHandler}>{showAllDescription ? t("business.button_status_1") : t("business.button_status_0")}</button>
                    </div>

                    <div className="flex items-center justify-between">
                        <a href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${business.address}`} target="_blank" rel="noreferrer" className="text-white underline">{business.address}</a>

                        <div className="flex space-x-4">
                            <BookingHandler business={business} />

                            <button className="w-10" onClick={shareHandler}>
                                <img className="w-10" src="/assets/images/sharer.png" alt="share restaurant" />
                            </button>

                            <a className="w-10" href={`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${business.address}`} target="_blank" rel="noreferrer">
                                <img className="w-10" src="/assets/images/gps.png" alt="go to google maps" />
                            </a>
                        </div>
                    </div>

                    {business.arzignano_food_services && (business.arzignano_food_services.table_service || business.arzignano_food_services.delivery || business.arzignano_food_services.takeaway) ?
                        <div className="text-white">
                            <p>Servizi disponibili</p>
                            <div className="pt-2 flex space-x-4">
                                {business.arzignano_food_services.table_service &&
                                    <div className="flex flex-col items-center">
                                        <img className="w-9 h-9 p-1 bg-white rounded" src="/assets/images/table_service.svg" />
                                        <p className="pt-1 text-sm">Al tavolo</p>
                                    </div>}
                                {business.arzignano_food_services.delivery &&
                                    <div className="flex flex-col items-center">
                                        <img className="w-9 h-9 p-1 bg-white rounded" src="/assets/images/delivery.svg" />
                                        <p className="pt-1 text-sm">Delivery</p>
                                    </div>}
                                {business.arzignano_food_services.takeaway &&
                                    <div className="flex flex-col items-center">
                                        <img className="w-9 h-9 p-1 bg-white rounded" src="/assets/images/takeaway.svg" />
                                        <p className="pt-1 text-sm">Takeaway</p>
                                    </div>}
                            </div>
                        </div> :
                        null}
                </div>

                <div className="relative pb-20">
                    {business && business.images_gallery && <Gallery business={business} />}

                    <BusinessTabs business={business} />
                </div>
            </div>

            <Footer />
        </div>
    );
}

export default Business;
