import { Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import BusinessesWrapper from "./pages/BusinessesWrapper";
import Business from "./pages/Business";
import Places from "./pages/Places";
import Events from "./pages/Events";
import Entertainment from "./pages/Entertainment";
import Transport from "./pages/Transport";
import Sos from "./pages/Sos";

function AppRoutes() {
    return (
        <Routes>
            <Route exact path="/" element={<Home />} />
            <Route exact path="/arzignano" element={<BusinessesWrapper />} />
            <Route exact path="/arzignano/:token/menu" element={<Business />} />
            <Route exact path="/eventi" element={<Events />} />
            <Route exact path="/cosa-visitare" element={<Places />} />
            <Route exact path="/cinema-e-teatro" element={<Entertainment />} />
            <Route exact path="/trasporti-taxi" element={<Transport />} />
            <Route exact path="/sos-emergenze" element={<Sos />} />
        </Routes>
    );
}

export default AppRoutes;
