import { useState, useEffect } from 'react';
import network from '../../apis/network';

function useNetwork(props) {
    const { city, pacchetti } = props;

    const [business, setBusiness] = useState({ restaurants: [], shops: [] });

    useEffect(() => {
        async function handleBusinessLoad() {
            const response = await network.doGetGroupRestaurants(process.env.REACT_APP_NAMESPACE);
            const restaurants = response.filter(restaurant => restaurant.category == "restaurant")
            const shops = response.filter(shop => shop.category == "shop")

            setBusiness({ restaurants, shops });
        }

        handleBusinessLoad();
    }, []);

    return business ? business : [];
}

export default useNetwork;
