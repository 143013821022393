import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";
import { useState } from "react";
import eventsApi from "../apis/events";

function Sos() {
    const { t } = useTranslation();

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewsos", {}, false);
    }, []);

    return (
        <div>
            <div className="relative">
                <div className="h-56 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/sos.png)` }}></div>

                <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 z-10"></div>

                <Link to={`/`} className="absolute top-0 m-4 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full z-20">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <p className="absolute bottom-0 p-4 text-2xl text-white z-20 uppercase">{t("home.tiles.sos")}</p>
            </div>

            <div className="px-6 py-8 space-y-4">
                <p>Premi il pulsante di cui hai bisogno per telefonare.</p>

                <div className="space-y-4 text-center">
                    <a href="tel:112" className="block w-full p-4 text-white uppercase border-2 border-arzignano-red rounded-lg bg-arzignano-red">emergenze - 112</a>
                    <a href="tel:118" className="block w-full p-4 uppercase rounded-lg border-2 border-arzignano-red">ambulanza - 118</a>
                    <a href="tel:115" className="block w-full p-4 uppercase rounded-lg border-2 border-arzignano-red">vigili del fuoco - 115</a>
                    <a href="tel:+39 335 5837277" className="block w-full p-4 uppercase rounded-lg border-2 border-arzignano-red">polizia locale</a>
                </div>

                <p className="text-center">Pronto intervento diurno e serale<a href="tel:+393355837277" className="underline text-blue-700"></a></p>

                <div className="py-4 space-y-4">
                    <p className="font-bold uppercase">attenzione</p>
                    <p className="font-bold uppercase">sistema di videosorveglianza ad intelligenza artificiale a servizio dei cittadini</p>
                    <p>Il comune di Arzignano è dotato di un complesso sistema di telecamere targasystem ubicate agli ingressi e uscite della città. Grazie all’intelligenza artificiale, il sistema esegue, controlla e registra automaticamente circa 70 milioni di controlli annuali. In caso di incidenti, auto pirata o crimini riconducibili ad autoveicoli, è possibile contattare la POLIZIA LOCALE COMANDO DI ARZIGNANO <span className="block">- Sezione Polizia Giudiziaria e Servizi Tecnologici.</span><span>Telefono: <a className="underline text-blue-700" href="tel:+390444476642">+39 0444 476642</a></span></p>
                    <a href="mailto:dalbarco.maurizio@comune.arzignano.vi.it" className="block underline text-blue-700">dalbarco.maurizio@comune.arzignano.vi.it</a></div>
            </div>

            <Footer />
        </div>
    );
}

export default Sos;
