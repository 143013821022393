import * as Dialog from '@radix-ui/react-dialog';
import { useGetBusiness } from '../logic/contexts/BusinessContext';
import { trackDataV2 } from '../apis/tracking';
import IngredientList from './IngredientList';
import { t } from 'i18next';
import formats from '../utils/formats';
import { Link, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useLayoutEffect } from 'react';
import positionProvider from '../apis/position';
import { useEffect } from 'react';
import dishesLogic from '../apis/dishes';

function InMenuModal(props) {
    const { result, userPosition } = props;

    const [distance, setDistance] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [dishesIdx, setDishesIdx] = useState(0);

    const distanceRef = useRef();
    const rootRef = useRef();
    const { t } = useTranslation();

    useLayoutEffect(() => {
        async function getDistance() {
            if (userPosition.lat && userPosition.lng) {
                const res = await positionProvider.getDistanceFromTwoPoints({ lat: userPosition.lat, lng: userPosition.lng }, { lat: result.latitude, lng: result.longitude });
                setDistance(res);
            }
        }

        getDistance();
    }, [userPosition])

    useEffect(() => {
        async function fetch() {
            await dishesLogic.doGetIngredientsToDish(result.piatti, "it");
        }

        fetch();
    }, [])

    const onClickHandler = (evt) => {
        console.log(distanceRef.current)
        console.log(rootRef.current)

        if (rootRef.current == distanceRef.current) {
            setIsOpen(false);
            window.open(`https://www.google.com/maps/dir/?api=1&travelmode=driving&layer=traffic&destination=${result.address}`);
        } else {
            setIsOpen(false);
        }
    }

    return (
        <Dialog.Root open={isOpen} onOpenChange={() => setIsOpen(prev => !prev)}>
            <Dialog.Trigger ref={rootRef} className="relative w-full">
                <div className="cursor-pointer flex mt-4 mb-8 items-center text-left border rounded-xl shadow-xl">
                    {result && result.piatti[0] && <img className="w-32 h-32 p-2 object-cover rounded-xl" src={result.piatti[0].image_url} alt="Immagine rappresentativa del ristorante" />}

                    <div className="flex flex-col w-full space-y-4 p-2">
                        <p className="font-bold">{formats.truncate(result.name, 18)}</p>

                        <p className="text-sm">{formats.truncate(result.address, 28)}</p>

                        {result.in_menu &&
                            <p className="text-sm">{t("businessTile.p")}: {result.in_menu} {result.in_menu_total > 1 && "+ altri piatti"}</p>}

                        <div className="flex justify-between items-center">
                            <Link className="underline text-sm text-blue-500" to={`/arzignano/${result.token}/menu`}>{t("businessTile.a")}</Link>

                            <div className="block px-2 text-sm border rounded-full z-30" ref={distanceRef}>{distance}</div>

                            <div className="w-8 p-2 bg-blue-500 rounded-full shadow-2xl shadow-black">
                                <svg xmlns="http://www.w3.org/2000/svg" className="text-white" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M268 112l144 144-144 144M392 256H100" /></svg>
                            </div>
                        </div>
                    </div>
                </div>
            </Dialog.Trigger>

            <Dialog.Portal>
                <Dialog.Overlay>
                    {isOpen && result.piatti &&
                        <Dialog.Content className="z-50 max-w-md fixed w-full top-0 left-0 right-0 bottom-0 m-auto overflow-y-scroll bg-white">
                            <div className="flex justify-center items-center">
                                <Dialog.Trigger className="z-50 outline-none absolute top-0 left-0 mx-8 my-6 rounded-full p-2 bg-white shadow-2xl shadow-black">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="w-6 text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                                </Dialog.Trigger>

                                <img className="w-full object-cover" alt={result.piatti[dishesIdx].name} src={result.piatti[dishesIdx].image_url} />
                            </div>


                            <div className="p-8 py-4 space-y-4">
                                <div className="space-y-6">
                                    <p className="pt-14 text-2xl">{result.piatti[dishesIdx].name}</p>
                                    <p className="text-sm">{result.piatti[dishesIdx].description}</p>

                                    <Link className="block my-6 underline" to={`/arzignano/${result.token}/menu`}>{result.name}</Link>

                                    <p className="text-xl font-semibold">{t("dishModal.p_1")}</p>

                                    {result.piatti[dishesIdx].ingredients &&
                                        <ul className="list-disc px-4">
                                            {result.piatti[dishesIdx].ingredients.map((ingredient, i) => <IngredientList ingredient={ingredient} key={i} />)}
                                        </ul>}

                                    <p className="text-gray-600">{result.piatti[dishesIdx].price_formatted}</p>

                                    <span className="flex border border-gray-200"></span>

                                    {result.piatti[dishesIdx].has_allergens ?
                                        <div className="allergens-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
                                            <svg className="w-6 flex-none mr-4 fill-current text-red-500" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512"><path d="M85.57 446.25h340.86a32 32 0 0028.17-47.17L284.18 82.58c-12.09-22.44-44.27-22.44-56.36 0L57.4 399.08a32 32 0 0028.17 47.17z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path xmlns="http://www.w3.org/2000/svg" d="M250.26 195.39l5.74 122 5.73-121.95a5.74 5.74 0 00-5.79-6h0a5.74 5.74 0 00-5.68 5.95z" fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="32" /><path d="M256 397.25a20 20 0 1120-20 20 20 0 01-20 20z" /></svg>
                                            <div>
                                                <p className="text-sm text-red-500">{t("dishModal.p_2")}</p>
                                            </div>
                                        </div>
                                        : null}

                                    {result.piatti[dishesIdx].is_frozen ?
                                        <div className="frozen-warning flex items-center justify-center py-6 pr-4 border-t border-t-gray-200">
                                            <img className="flex-none mr-4 text-red-500" alt="" />
                                            <div>
                                                <p className="text-sm text-red-500">{t("dishModal.p_3")}</p>
                                            </div>
                                        </div>
                                        : null}
                                </div>

                                <div className="flex justify-center overflow-x-scroll space-x-4">
                                    {Array(result.piatti.length).fill(null).map((_, idx) => (
                                        <input className="w-5 h-5" type="radio" id="huey" name="drone" value={idx} defaultChecked={idx == 0 ? true : false} onClick={() => setDishesIdx(idx)} />
                                    ))}
                                </div>
                            </div>
                        </Dialog.Content>}
                </Dialog.Overlay>
            </Dialog.Portal>
        </Dialog.Root>
    );
}

export default InMenuModal;
