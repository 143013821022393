import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import places from "../apis/places.json";
import { trackDataV2 } from "../apis/tracking";
import Footer from "../components/Footer";
import PlaceModal from "../components/PlaceModal";

function Places() {
    const { t } = useTranslation();

    useEffect(() => {
        trackDataV2(3, "1e8ve3m3z", "viewplaces", {}, false);
    }, [])

    return (
        <div>
            <div className="relative">
                <div className="h-56 px-6 py-8 bg-cover bg-center" style={{ backgroundImage: `url(/assets/images/castle.png)` }}></div>

                <div className="absolute inset-0 bg-gradient-to-t from-black opacity-75 z-10"></div>

                <Link to={`/`} className="absolute top-0 m-4 p-2 w-10 h-10 bg-white shadow-2xl shadow-black rounded-full z-20">
                    <svg xmlns="http://www.w3.org/2000/svg" className="text-blue-500" viewBox="0 0 512 512"><path fill="none" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="48" d="M244 400L100 256l144-144M120 256h292" /></svg>
                </Link>

                <p className="absolute bottom-0 p-4 text-2xl text-white z-20 uppercase">{t("home.tiles.places")}</p>
            </div>

            {places.map((place, i) =>
                <div className="px-6" key={i}>
                    <PlaceModal place={place} />
                </div>)}

            <Footer />
        </div>
    );
}

export default Places;
