import { useEffect, useState } from "react"
import { useTranslation } from "react-i18next";

function useGeoPosition() {
    const [position, setPosition] = useState({ lat: 45.52111745960698, lng: 11.332452245042228, error: "" });
    const [error, setError] = useState(null);

    const { t } = useTranslation();

    const onChange = ({ coords }) => {
        setPosition({ lat: coords.latitude, lng: coords.longitude, });
    };
    const onError = (error) => {
        setError(t("geoError"));
    };

    useEffect(() => {
        const geo = navigator.geolocation;
        if (!geo) {
            setError(t("geoError"));
            return;
        }
        let watcher = geo.watchPosition(onChange, onError);
        return () => geo.clearWatch(watcher);
    }, []);

    return { ...position, error };
}

export default useGeoPosition;
